<script lang="ts" setup>
  import type { Entry } from 'contentful'
  import type { TypePageSkeleton } from '@/types/contentful'

  useOrderAtTable()

  const { data, error } =
    await useFetch<Entry<TypePageSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>>(`/api/cms/page/home`)

  if (!data.value || error.value) {
    throw createError({
      ...error.value,
      statusCode: 404,
      statusMessage: 'No Home Data. Page not found',
      fatal: true
    })
  }

  useGrilldServerSeoMeta(data.value?.fields.seo)
  useGrilldSeoHead({
    pageTitle: data.value?.fields.seo?.fields.metaTitle ?? undefined,
    canonicalUrl: data.value?.fields.seo?.fields.canonicalUrl ?? undefined,
    jsonLinkingData: data.value?.fields.seo?.fields.jsonLinkingData || undefined
  })

  /**
   * @description if query param 'order-at-table' is present, open the order-at-table page in the ordering app
   */
  function useOrderAtTable() {
    const query = useRoute().query

    if (query['order-at-table']) {
      const { orderingAppNavTo } = useOrderingAppNavigation()

      orderingAppNavTo('/order-at-table/' + query['order-at-table'])
    }
  }
</script>

<template>
  <MPageModules v-if="data" :page-data="data" />
</template>
